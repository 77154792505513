import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {withApollo} from '@apollo/react-hoc';
import React, {useEffect, useReducer, useState} from 'react';
import {useCookies} from 'react-cookie';
import * as heap from '../heap';
import mutations from '../mutations/auth';
import queries from '../queries/auth';
import {env} from '../util/environment';

import moment from 'moment';
import {useRecordApplicationLoad, useRecordApplicationUnload} from '../hooks/analytics';

export const AuthContext = React.createContext({
	user: {},
	entitlements: [],
	FollowedMarkets: [],
	FollowedHubs: [],
	isAuthenticated: false,
	isFreeUser: false,
	isVerified: false,
	hasMarketsSelected: false,
	login: () => {},
	logout: () => {},
	checkSession: () => {},
	authModalToShow: null,
	setAuthModalToShow: () => {},
});

const SESSION_CHECK_INTERVAL = 60000;
const INITIAL_AUTH_STATE = {
	isAuthenticated: false,
	userData: null,
	userLoggedOut: false,
	isFreeUser: false,
	isVerified: false,
	hasMarketsSelected: false,
};

const hostname = () => {
	const firstDotIdx = window.location.hostname.indexOf('.');
	if (firstDotIdx >= 0) {
		return window.location.hostname.slice(firstDotIdx);
	} else {
		return window.location.hostname;
	}
};

const authReducer = (authState, action) => {
	switch (action.type) {
		case 'LOGIN_FAIL':
			return {
				...authState,
				isAuthenticated: false,
				userData: null,
				isFreeUser: false,
				isVerified: false,
				hasMarketsSelected: false,
			};
		case 'LOGIN_SUCCESS':
			return {
				isAuthenticated: true,
				userData: action.userData,
				userLoggedOut: false,
				isFreeUser: action.userData.CreatedOrigin === 'BART',
				isVerified: action.userData.VerifiedAt !== null,
				hasMarketsSelected: action.userData.Entitlements.MarketIDs.length !== 0,
			};
		case 'USER_LOGOUT':
			return {
				isAuthenticated: false,
				userData: null,
				userLoggedOut: true,
				isFreeUser: false,
				isVerified: false,
				hasMarketsSelected: false,
			};
		case 'INITIAL_CHECK_SUCCESS':
			return {
				isAuthenticated: true,
				userData: action.userData,
				userLoggedOut: false,
				isFreeUser: action.userData.CreatedOrigin === 'BART',
				isVerified: action.userData.VerifiedAt !== null,
				hasMarketsSelected: action.userData.Entitlements.MarketIDs.length !== 0,
			};
		case 'INITIAL_CHECK_FAIL':
			return {
				isAuthenticated: false,
				userData: null,
				userLoggedOut: false,
				isFreeUser: false,
				isVerified: false,
				hasMarketsSelected: false,
			};
		case 'SESSION_CHECK_FAIL':
			// Potentially allow a few failed session checks before logging out
			return {
				isAuthenticated: false,
				userData: null,
				userLoggedOut: false,
				isFreeUser: false,
				isVerified: false,
				hasMarketsSelected: false,
			};
		case 'SESSION_CHECK_SUCCESS':
			return {...authState, isAuthenticated: true};
		case 'PROFILE_UPDATE':
			return {...authState, userData: {...authState.userData, ...action.userData}};
		default:
			return authState;
	}
};

const AuthContextProvider = (props) => {
	const [authState, dispatchAuth] = useReducer(authReducer, INITIAL_AUTH_STATE);
	const [initialAuthCheck, setinitialAuthCheck] = useState(false);
	const [accessError, setAccessError] = useState(null);
	const [formActions, setFormActions] = useState({});
	const [registerFormActions, setRegisterFormActions] = useState({});
	const [authModalToShow, setAuthModalToShow] = useState(null);
	const [login, {error: loginStateError, data: loginStateData, loading: loginStateLoading}] = useMutation(
		mutations.LOGIN
	);
	const [register, {error: registerStateError, data: registerStateData, loading: registerStateLoading}] = useMutation(
		mutations.REGISTER
	);
	const [
		refreshSession,
		{error: refreshSessionError, data: refreshSessionData, loading: refreshSessionLoading},
	] = useMutation(mutations.REFRESH_SESSION);
	const {data: authCheckData, loading: authCheckLoading, refetch: refetchCurrentUser} = useQuery(
		queries.INITIAL_AUTH_CHECK
	);
	const [
		runSessionCheck,
		{data: sessionCheckData, loading: sessionCheckLoading, stopPolling: stopSessionCheck},
	] = useLazyQuery(queries.SESSION_CHECK, {
		pollInterval: SESSION_CHECK_INTERVAL,
	});
	const recordLoad = useRecordApplicationLoad();
	const recordUnload = useRecordApplicationUnload();

	const [, , removeCookie] = useCookies([`${env.REACT_APP_COOKIE_NAME}.sid`]);

	const updateProfile = (profileData) => {
		dispatchAuth({
			type: 'PROFILE_UPDATE',
			userData: profileData,
		});
	};

	useEffect(() => {
		if (!authCheckLoading) {
			if (authCheckData && authCheckData.getCurrentUser) {
				dispatchAuth({
					type: 'INITIAL_CHECK_SUCCESS',
					userData: authCheckData.getCurrentUser,
				});
				runSessionCheck();
			} else {
				dispatchAuth({type: 'INITIAL_CHECK_FAIL'});
				removeCookie(`${env.REACT_APP_COOKIE_NAME}.sid`, {path: '/', domain: hostname()});
			}
			setinitialAuthCheck(true);
		}
		// eslint-disable-next-line
	}, [authCheckLoading, authCheckData]);

	useEffect(() => {
		if (!sessionCheckLoading) {
			if (sessionCheckData && sessionCheckData.checkSession) {
				if (!sessionCheckData.checkSession.isAuthenticated) {
					dispatchAuth({type: 'SESSION_CHECK_FAIL'});
					removeCookie(`${env.REACT_APP_COOKIE_NAME}.sid`, {path: '/', domain: hostname()});
				}
			}
		}
	}, [sessionCheckLoading, sessionCheckData, removeCookie]);

	useEffect(() => {
		if (loginStateData && loginStateData.hasOwnProperty('login') && loginStateData.login.success) {
			props.client.cache.reset();
			dispatchAuth({
				type: 'LOGIN_SUCCESS',
				userData: loginStateData.login.userData,
			});
			recordLoad();
			runSessionCheck();
		} else if (loginStateData && loginStateData.login.loginLock) {
			setAccessError(
				'Too many login attempts. Try again ' + moment.utc(loginStateData.login.loginLock.expiresAt).fromNow() + '.'
			);
			dispatchAuth({type: 'LOGIN_FAIL'});
		} else if (loginStateData && loginStateData.hasOwnProperty('login') && !loginStateData.login.success) {
			setAccessError('Incorrect username and/or password');
			dispatchAuth({type: 'LOGIN_FAIL'});
		}
		// eslint-disable-next-line
	}, [loginStateData]);

	useEffect(() => {
		if (registerStateData && registerStateData.register && registerStateData.register.success) {
			props.client.cache.reset();
			dispatchAuth({
				type: 'LOGIN_SUCCESS',
				userData: registerStateData.register.userData,
			});
			recordLoad();
			runSessionCheck();
		}
		// eslint-disable-next-line
	}, [registerStateData]);

	const loginHandler = (email, password, remember, formActions) => {
		login({variables: {email: email, password: password, remember: remember}});
		setFormActions(formActions);
	};

	const registerHandler = (email, password, formActions) => {
		register({variables: {email, password}});
		setRegisterFormActions(formActions);
	};

	useEffect(() => {
		if (refreshSessionData && refreshSessionData.refreshSession) {
			dispatchAuth({
				type: 'LOGIN_SUCCESS',
				userData: refreshSessionData.refreshSession,
			});
		}
		// eslint-disable-next-line
	}, [refreshSessionData]);

	useEffect(() => {
		if (loginStateData && loginStateData.hasOwnProperty('login')) {
			formActions.setSubmitting(false);
		}
		// eslint-disable-next-line
	}, [loginStateData]);

	useEffect(() => {
		if (registerStateData) {
			registerFormActions.setSubmitting(true);
		}
	}, [registerStateData, registerFormActions]);

	useEffect(() => {
		if (registerStateError) {
			registerFormActions.setSubmitting(false);
		}
	}, [registerStateError, registerFormActions]);

	useEffect(() => {
		if (authState.userData) {
			heap.identify(authState.userData.ID);
		}
	}, [authState]);

	const logoutHandler = () => {
		recordUnload();
		removeCookie(`${env.REACT_APP_COOKIE_NAME}.sid`, {path: '/', domain: hostname()});
		props.client.cache.reset();

		dispatchAuth({type: 'USER_LOGOUT'});
		if (stopSessionCheck) {
			stopSessionCheck();
		}
		setAuthModalToShow(null);
		fetch(`${env.REACT_APP_API_DOMAIN}/api/auth/logout`, {credentials: 'include'});
	};

	return (
		<AuthContext.Provider
			value={{
				isAuthenticated: authState.isAuthenticated,
				initialAuthChecked: initialAuthCheck,
				login: loginHandler,
				loginState: {
					error: loginStateError || accessError,
					data: loginStateData,
					loading: loginStateLoading,
				},
				register: registerHandler,
				registerState: {
					error: registerStateError,
					data: registerStateData,
					loading: registerStateLoading,
				},
				logout: logoutHandler,
				loggedOut: authState.userLoggedOut,
				logoutState: {
					error: null,
					loading: null,
					data: null,
				},
				refreshSessionState: {
					error: refreshSessionError,
					data: refreshSessionData,
					loading: refreshSessionLoading,
				},
				initialAuthLoading: sessionCheckLoading,
				authModalToShow,
				setAuthModalToShow,
				userData: authState.userData,
				refetchCurrentUser,
				refreshSession: refreshSession,
				updateProfile,
				isFreeUser: authState.isFreeUser,
				isVerified: authState.isVerified,
				hasMarketsSelected: authState.hasMarketsSelected,
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
};

export default withApollo(AuthContextProvider);
