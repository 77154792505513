export const env = setEnvironmentVariablesForEnv();

export function setEnvironmentVariablesForEnv() {
	const internalEnv = {};
	internalEnv.NODE_ENV = process.env.REACT_APP_NODE_WOMAN_NODE_ENV;
	if (internalEnv.NODE_ENV === 'production') {
		internalEnv.REACT_APP_API_DOMAIN = process.env.REACT_APP_PROD_API_DOMAIN;
		internalEnv.REACT_APP_WS_ENDPOINT = process.env.REACT_APP_PROD_WS_ENDPOINT;
		internalEnv.REACT_APP_COOKIE_NAME = process.env.REACT_APP_PROD_COOKIE_NAME;
		internalEnv.REACT_APP_COOKIE_DOMAIN = process.env.REACT_APP_PROD_COOKIE_DOMAIN;
		internalEnv.REACT_APP_SHEETS_API_KEY = process.env.REACT_APP_PROD_SHEETS_API_KEY;
		internalEnv.REACT_APP_HEAP_ID = process.env.REACT_APP_PROD_HEAP_ID;
		internalEnv.REACT_APP_LANDING_PAGE_DOMAIN = process.env.REACT_APP_PROD_LANDING_PAGE_DOMAIN;
		internalEnv.REACT_APP_CLIENT_ID = process.env.REACT_APP_PROD_CLIENT_ID;
		internalEnv.REACT_APP_COPILOT_URL = process.env.REACT_APP_PROD_COPILOT_URL;
	} else if (internalEnv.NODE_ENV === 'staging') {
		internalEnv.REACT_APP_API_DOMAIN = process.env.REACT_APP_STAGING_API_DOMAIN;
		internalEnv.REACT_APP_WS_ENDPOINT = process.env.REACT_APP_STAGING_WS_ENDPOINT;
		internalEnv.REACT_APP_COOKIE_NAME = process.env.REACT_APP_STAGING_COOKIE_NAME;
		internalEnv.REACT_APP_COOKIE_DOMAIN = process.env.REACT_APP_STAGING_COOKIE_DOMAIN;
		internalEnv.REACT_APP_SHEETS_API_KEY = process.env.REACT_APP_STAGING_SHEETS_API_KEY;
		internalEnv.REACT_APP_HEAP_ID = process.env.REACT_APP_STAGING_HEAP_ID;
		internalEnv.REACT_APP_LANDING_PAGE_DOMAIN = process.env.REACT_APP_STAGING_LANDING_PAGE_DOMAIN;
		internalEnv.REACT_APP_CLIENT_ID = process.env.REACT_APP_STAGING_CLIENT_ID;
		internalEnv.REACT_APP_COPILOT_URL = process.env.REACT_APP_STAGING_COPILOT_URL;
	} else {
		internalEnv.REACT_APP_API_DOMAIN = process.env.REACT_APP_DEV_API_DOMAIN;
		internalEnv.REACT_APP_WS_ENDPOINT = process.env.REACT_APP_DEV_WS_ENDPOINT;
		internalEnv.REACT_APP_COOKIE_NAME = process.env.REACT_APP_DEV_COOKIE_NAME;
		internalEnv.REACT_APP_COOKIE_DOMAIN = process.env.REACT_APP_DEV_COOKIE_DOMAIN;
		internalEnv.REACT_APP_SHEETS_API_KEY = process.env.REACT_APP_DEV_SHEETS_API_KEY;
		internalEnv.REACT_APP_HEAP_ID = process.env.REACT_APP_DEV_HEAP_ID;
		internalEnv.REACT_APP_LANDING_PAGE_DOMAIN = process.env.REACT_APP_DEV_LANDING_PAGE_DOMAIN;
		internalEnv.REACT_APP_CLIENT_ID = process.env.REACT_APP_DEV_CLIENT_ID;
		internalEnv.REACT_APP_COPILOT_URL = process.env.REACT_APP_DEV_COPILOT_URL;
	}
	return internalEnv;
}
