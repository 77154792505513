export const TYPE_ENUM = {
	MARKET: 'market',
	HUB: 'hub',
	ANALYST: 'analyst',
	HOME: 'home',
};

export const TYPE_TO_URL_MAP = {
	[TYPE_ENUM.MARKET]: '/market/:marketName',
	[TYPE_ENUM.HUB]: '/hub/:id/:slug?',
	[TYPE_ENUM.ANALYST]: '/analyst/:username',
	[TYPE_ENUM.HOME]: '/',
};

// NOTE: ADD NEW URL -> TYPES BEFORE HOME
export const URL_TO_TYPE_MAP = {
	[TYPE_TO_URL_MAP[TYPE_ENUM.MARKET]]: TYPE_ENUM.MARKET,
	[TYPE_TO_URL_MAP[TYPE_ENUM.HUB]]: TYPE_ENUM.HUB,
	[TYPE_TO_URL_MAP[TYPE_ENUM.ANALYST]]: TYPE_ENUM.ANALYST,
	[TYPE_TO_URL_MAP[TYPE_ENUM.HOME]]: TYPE_ENUM.HOME,
};
